import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  Content,
  Team,
  Reviews,
  CustomerReviewModal,
} from "@bluefin/components";
import { Header, Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"about-hero"}>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
          subfooter={false}
          className={"about-page"}
        >
          <Content className={"about-content"}>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={16}>
              <Header as={"h1"}>About Us</Header>
            </Content.Markup>
            <Content.Markup width={16}>
              {fishermanBusiness.aboutMarkdown}
            </Content.Markup>
          </Content>
          <Grid
            stackable={true}
            className={"component-section-container team-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14}>
              <h1>Our Team</h1>
              <Team
                team={[
                  {
                    name: "Lauren Brown",
                    role: "Manager",
                    description:
                      "Lauren has been with the company for eight years. She strives to make every visit memorable.",
                    image:
                      "https://s3.amazonaws.com/daysmartonlinebooking/accounts/ac0cfa10-cd6f-4ce5-9bc5-4d1d8d0cef0d/images/employees/model/1000.png",
                  },
                  {
                    name: "Bernard D. Davidson",
                    role: "General Staff",
                    description:
                      "Bernard is the first face you’ll see and speak to when you enter our space,our stylists right hand when it comes to helping around the salon.",
                    image:
                      "https://s3.amazonaws.com/daysmartonlinebooking/accounts/ac0cfa10-cd6f-4ce5-9bc5-4d1d8d0cef0d/images/employees/model/1002.png",
                  },
                  {
                    name: "Pierce Jackman",
                    role: "Front Desk",
                    description:
                      "Pierce wants you to feel like family when you come in. Just let him know what you need, and Pierce is your guy!",
                    image:
                      "https://s3.amazonaws.com/daysmartonlinebooking/accounts/ac0cfa10-cd6f-4ce5-9bc5-4d1d8d0cef0d/images/employees/model/1006.png",
                  },
                ]}
                as={"verticalItem"}
                itemsPerRow={3}
                header={""}
              />
            </Grid.Column>
          </Grid>
          <div className={"reviews-container"}>
            <Reviews
              reviews={fishermanBusiness.reviews}
              as={"card"}
              buttonBasic={false}
              buttonSize={"medium"}
              centerHeader={true}
              cardInlinePhoto={"left"}
              header={"Customer Reviews"}
              imageCircular={false}
              imageSize={"small"}
              itemButtonFloat={false}
              itemCenterContent={false}
              itemsDivided={false}
              itemsPerRow={3}
              linkAs={"none"}
              onlyEvenRows={true}
              showImage={true}
              verticalItemAlign={"left"}
            />
            <CustomerReviewModal
              businessId={fishermanBusiness._id}
              locations={allFishermanBusinessLocation.nodes}
              businessType={"restaurant"}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      aboutMarkdown
      reviews {
        author
        text
        link
      }
      _id
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
